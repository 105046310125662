.btn {
    @apply inline-flex items-center px-12 py-6 font-extrabold text-center border border-transparent rounded transition-all duration-200 ease-linear focus:outline-none;

    & > i {
        @apply mr-10 font-semibold text-lg;
    }

    &:disabled {
        @apply pointer-events-none opacity-75;
    }

    &-primary {
        @apply bg-primary text-white hover:bg-primary-600 focus:bg-primary-600;
    }

    &-secondary {
        @apply bg-light-300 text-gray-500 hover:bg-light-600 focus:bg-light-800;
    }

    &-success {
        @apply bg-green-500 text-white hover:bg-green-600 focus:bg-green-600;
    }

    &-danger {
        @apply bg-red-500 text-white hover:bg-red-600 focus:bg-red-600;
    }

    &-warning {
        @apply bg-yellow-500 text-white hover:bg-yellow-600 focus:bg-yellow-600;
    }

    &-info {
        @apply bg-blue-500 text-white hover:bg-blue-600 focus:bg-blue-600;
    }

    &-outline-primary {
        @apply text-primary border-primary hover:bg-primary-600 hover:text-white focus:bg-primary-600 focus:text-white;
    }

    &-outline-secondary {
        @apply text-gray-500 border-light-800 hover:bg-light-600 hover:text-gray-500 focus:bg-light-600 focus:text-gray-500;
    }

    &-outline-success {
        @apply text-green-500 border-green-500 hover:bg-green-600 hover:text-white focus:bg-green-600 focus:text-white;
    }

    &-outline-danger {
        @apply text-red-500 border-red-500 hover:bg-red-600 hover:text-white focus:bg-red-600 focus:text-white;
    }

    &-outline-warning {
        @apply text-yellow-500 border-yellow-500 hover:bg-yellow-600 hover:text-white focus:bg-yellow-600 focus:text-white;
    }

    &-outline-info {
        @apply text-blue-500 border-blue-500 hover:bg-blue-600 hover:text-white focus:bg-blue-600 focus:text-white;
    }

    &-icon {
		@apply w-26 h-26 p-0 justify-center text-xl leading-none;
	}
}

.btn-rounded {
    @apply rounded-full;
}