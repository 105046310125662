* {
    @apply outline-none;
}

html {
    @apply relative min-h-full text-base font-sans;
}

body {
    @apply flex flex-col bg-light m-0 overflow-x-hidden font-sans text-base font-normal tracking-widest text-gray-500;
}

a {
    &:active {
        @apply outline-none no-underline;
    }

    &:focus {
        @apply outline-none no-underline;
    }
    
    &:hover {
        @apply outline-none no-underline;
    }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    @apply my-10 mx-0 font-medium text-gray-500
}

h1, .h1 {
    @apply text-h1 leading-43;
}

h2, .h2 {
    @apply text-h2 leading-35;
}

h3, .h3 {
    @apply text-h3 leading-30;
}

h4, .h4 {
    @apply text-h4 leading-22;
}

h5, .h5 {
    @apply text-h5;
}

h6, .h6 {
    @apply text-h6;
}

p {
    font-size: .8125rem;
    line-height: 1.6;
    @apply font-normal;
}

b, strong {
    @apply font-bold;
}

label {
    @apply inline-block;
}