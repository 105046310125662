.form-control {
    @apply block w-full font-normal leading-24 text-13 px-8 py-2 bg-white border border-gray-400 focus:border-primary-400 focus:outline-none rounded;

    &-sm {
        @apply px-8 py-0 text-11 rounded;
    }

    &-lg {
        @apply px-8 py-4 text-xl rounded-md;
    }

    &-sucess {
        @apply border-green-500 focus:border-green-500;
    }

    &-warning {
        @apply border-yellow-500 focus:border-yellow-500;
    }

    &-danger {
        @apply border-red-500 focus:border-red-500;
    }
}