.input-group {
	@apply flex w-full;

	.input-group-item {
		&:not(:first-child) {
			@apply rounded-tl-none rounded-bl-none;
		}

		&:not(:last-child) {
			@apply rounded-tr-none rounded-br-none;
		}
	}

	.input-addon {
		@apply inline-flex whitespace-nowrap items-center bg-gray-100 border border-gray-400 px-16 rounded text-gray-500;

		&-prepend {
			border-right-color: transparent;
		}

		&-append {
			border-left-color: transparent;
		}
	}

	.btn {
		@apply rounded whitespace-nowrap;

		+ .btn {
			border-left-color: theme("colors.white");
		}
	}

	input {
		+ input {
			border-left-color: transparent;
		}
	}
}