.dark {
	.tippy-box {
		&[data-theme~="light-border"] {
			@apply bg-gray-800 text-gray-500 border-gray-900;

			> .tippy-backdrop {
				@apply bg-gray-800;
			}
			&[data-placement^="top"] > .tippy-arrow {
				&::before {
					border-top-color: theme("colors.gray.800");
				}
				&::after {
					border-top-color: theme("colors.gray.900");
				}
			}

			&[data-placement^="bottom"] > .tippy-arrow {
				&::before {
					border-bottom-color: theme("colors.gray.800");
				}
				&::after {
					border-bottom-color: theme("colors.gray.900");
				}
			}
			&[data-placement^="left"] > .tippy-arrow {
				&::before {
					border-left-color: theme("colors.gray.800");
				}
				&::after {
					border-left-color: theme("colors.gray.900");
				}
			}
			&[data-placement^="right"] > .tippy-arrow {
				&::before {
					border-right-color: theme("colors.gray.800");
				}
				&::after {
					border-right-color: theme("colors.gray.900");
				}
			}

			> .tippy-svg-arrow {
				fill: theme("colors.gray.800");
			}
		}
	}
}

.tippy-box {
	&[data-theme~="light-border"] {
		@apply bg-white text-gray-700 rounded-xl border border-gray-200 shadow;

		> .tippy-backdrop {
			@apply bg-white;
		}

		&[data-placement^="top"] > .tippy-arrow {
			&::before {
				border-top-color: theme("colors.white");
			}
			&::after {
				border-top-color: theme("colors.gray.200");
			}
		}

		&[data-placement^="bottom"] > .tippy-arrow {
			&::before {
				border-bottom-color: theme("colors.white");
				bottom: 15px;
			}
			&::after {
				border-bottom-color: theme("colors.gray.200");
			}
		}
		&[data-placement^="left"] > .tippy-arrow {
			&::before {
				border-left-color: theme("colors.white");
			}
			&::after {
				border-left-color: theme("colors.gray.200");
			}
		}
		&[data-placement^="right"] > .tippy-arrow {
			&::before {
				border-right-color: theme("colors.white");
				right: 15px;
			}
			&::after {
				border-right-color: theme("colors.gray.200");
			}
		}

		> .tippy-svg-arrow {
			fill: theme("colors.white");
		}

		> .tippy-content {
			@apply p-0;

			.dropdown-menu {
				@apply block;
			}

			.custom-dropdown-menu {
				@apply block;
			}

			.search-select-menu {
				@apply block;
			}
		}
	}

	&[data-theme~="tooltip"] {
		> .tippy-content {
			@apply px-20 py-8;
		}
	}

	&[data-theme~="popover"] {
		> .tippy-content {
			@apply p-20;
		}
	}
}