.input-icon {
    @apply relative;

    &-left {
        & .form-control {
            @apply pl-30;
        }

        & span {
            @apply absolute flex items-center justify-items-center left-0 top-0 bottom-0 w-30 ml-10;
        }
    }

    &-right {
        & .form-control {
            @apply pr-35;
        }

        & span {
            @apply absolute flex items-center justify-items-center right-0 top-0 bottom-0 w-30;
        }
    }
}