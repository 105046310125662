.table {
    @apply w-full text-gray-600;

    & th {
        @apply text-gray-600 font-medium align-middle border-gray-300;
    }

    & > :not(:last-child) > :last-child > * {
        @apply border-gray-300;
    }
    
    & > :not(caption) > * > * {
        @apply px-5 py-7 border-b;
    }
    
    & tr:last-child td {
        @apply border-b-0;
    }

    & .thead-light th {
        @apply bg-light-600;
    }

    &-sm {
        & > :not(caption) > * > * {
            @apply p-4;
        }
    }

    &-md {
        & > :not(caption) > * > * {
            @apply px-5 py-15;
        }
    }
}

.table-bordered {
    & > :not(caption) > * {
        @apply border;
    }

    & > :not(caption) > * > * {
        @apply border;
    }
}

.table-striped {
    & > tbody > tr:nth-of-type(2n) {
        @apply bg-light-200;
    }
}

.table-hover {
    & > tbody > tr {
        @apply hover:bg-light-100;
    }
}