@layer base {
    @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        src: url('./OpenSans/open-sans-v20-latin-300.eot?#iefix') format('embedded-opentype'),
             url('./OpenSans/open-sans-v20-latin-300.woff2') format('woff2')
             url('./OpenSans/open-sans-v20-latin-300.woff') format('woff')
             url('./OpenSans/open-sans-v20-latin-300.ttf') format('truetype')
             url('./OpenSans/open-sans-v20-latin-300.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: italic;
        font-weight: 300;
        src: url('./OpenSans/open-sans-v20-latin-300italic.eot?#iefix') format('embedded-opentype'),
             url('./OpenSans/open-sans-v20-latin-300italic.woff2') format('woff2')
             url('./OpenSans/open-sans-v20-latin-300italic.woff') format('woff')
             url('./OpenSans/open-sans-v20-latin-300italic.ttf') format('truetype')
             url('./OpenSans/open-sans-v20-latin-300italic.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        src: url('./OpenSans/open-sans-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
             url('./OpenSans/open-sans-v20-latin-regular.woff2') format('woff2')
             url('./OpenSans/open-sans-v20-latin-regular.woff') format('woff')
             url('./OpenSans/open-sans-v20-latin-regular.ttf') format('truetype')
             url('./OpenSans/open-sans-v20-latin-regular.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: italic;
        font-weight: 400;
        src: url('./OpenSans/open-sans-v20-latin-italic.eot?#iefix') format('embedded-opentype'),
             url('./OpenSans/open-sans-v20-latin-italic.woff2') format('woff2')
             url('./OpenSans/open-sans-v20-latin-italic.woff') format('woff')
             url('./OpenSans/open-sans-v20-latin-italic.ttf') format('truetype')
             url('./OpenSans/open-sans-v20-latin-italic.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        src: url('./OpenSans/open-sans-v20-latin-600.eot?#iefix') format('embedded-opentype'),
             url('./OpenSans/open-sans-v20-latin-600.woff2') format('woff2')
             url('./OpenSans/open-sans-v20-latin-600.woff') format('woff')
             url('./OpenSans/open-sans-v20-latin-600.ttf') format('truetype')
             url('./OpenSans/open-sans-v20-latin-600.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: italic;
        font-weight: 600;
        src: url('./OpenSans/open-sans-v20-latin-600italic.eot?#iefix') format('embedded-opentype'),
             url('./OpenSans/open-sans-v20-latin-600italic.woff2') format('woff2')
             url('./OpenSans/open-sans-v20-latin-600italic.woff') format('woff')
             url('./OpenSans/open-sans-v20-latin-600italic.ttf') format('truetype')
             url('./OpenSans/open-sans-v20-latin-600italic.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        src: url('./OpenSans/open-sans-v20-latin-700.eot?#iefix') format('embedded-opentype'),
             url('./OpenSans/open-sans-v20-latin-700.woff2') format('woff2')
             url('./OpenSans/open-sans-v20-latin-700.woff') format('woff')
             url('./OpenSans/open-sans-v20-latin-700.ttf') format('truetype')
             url('./OpenSans/open-sans-v20-latin-700.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: italic;
        font-weight: 700;
        src: url('./OpenSans/open-sans-v20-latin-700italic.eot?#iefix') format('embedded-opentype'),
             url('./OpenSans/open-sans-v20-latin-700italic.woff2') format('woff2')
             url('./OpenSans/open-sans-v20-latin-700italic.woff') format('woff')
             url('./OpenSans/open-sans-v20-latin-700italic.ttf') format('truetype')
             url('./OpenSans/open-sans-v20-latin-700italic.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 800;
        src: url('./OpenSans/open-sans-v20-latin-800.eot?#iefix') format('embedded-opentype'),
             url('./OpenSans/open-sans-v20-latin-800.woff2') format('woff2')
             url('./OpenSans/open-sans-v20-latin-800.woff') format('woff')
             url('./OpenSans/open-sans-v20-latin-800.ttf') format('truetype')
             url('./OpenSans/open-sans-v20-latin-800.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Open Sans';
        font-style: italic;
        font-weight: 800;
        src: url('./OpenSans/open-sans-v20-latin-800italic.eot?#iefix') format('embedded-opentype'),
             url('./OpenSans/open-sans-v20-latin-800italic.woff2') format('woff2')
             url('./OpenSans/open-sans-v20-latin-800italic.woff') format('woff')
             url('./OpenSans/open-sans-v20-latin-800italic.ttf') format('truetype')
             url('./OpenSans/open-sans-v20-latin-800italic.svg#OpenSans') format('svg');
    }
}