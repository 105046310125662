@layer base {
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 100;
        src: url('./Roboto/roboto-v27-latin-100.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-100.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-100.woff') format('woff')
             url('./Roboto/roboto-v27-latin-100.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-100.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 100;
        src: url('./Roboto/roboto-v27-latin-100italic.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-100italic.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-100italic.woff') format('woff')
             url('./Roboto/roboto-v27-latin-100italic.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-100italic.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        src: url('./Roboto/roboto-v27-latin-300.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-300.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-300.woff') format('woff')
             url('./Roboto/roboto-v27-latin-300.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-300.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 300;
        src: url('./Roboto/roboto-v27-latin-300italic.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-300italic.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-300italic.woff') format('woff')
             url('./Roboto/roboto-v27-latin-300italic.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-300italic.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        src: url('./Roboto/roboto-v27-latin-regular.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-regular.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-regular.woff') format('woff')
             url('./Roboto/roboto-v27-latin-regular.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-regular.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 400;
        src: url('./Roboto/roboto-v27-latin-italic.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-italic.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-italic.woff') format('woff')
             url('./Roboto/roboto-v27-latin-italic.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-italic.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        src: url('./Roboto/roboto-v27-latin-500.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-500.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-500.woff') format('woff')
             url('./Roboto/roboto-v27-latin-500.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-500.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 500;
        src: url('./Roboto/roboto-v27-latin-500italic.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-500italic.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-500italic.woff') format('woff')
             url('./Roboto/roboto-v27-latin-500italic.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-500italic.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        src: url('./Roboto/roboto-v27-latin-700.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-700.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-700.woff') format('woff')
             url('./Roboto/roboto-v27-latin-700.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-700.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 700;
        src: url('./Roboto/roboto-v27-latin-700italic.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-700italic.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-700italic.woff') format('woff')
             url('./Roboto/roboto-v27-latin-700italic.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-700italic.svg#OpenSans') format('svg');
    }
    
    @font-face {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 900;
        src: url('./Roboto/roboto-v27-latin-900.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-900.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-900.woff') format('woff')
             url('./Roboto/roboto-v27-latin-900.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-900.svg#OpenSans') format('svg');
    }

    @font-face {
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 900;
        src: url('./Roboto/roboto-v27-latin-900italic.eot?#iefix') format('embedded-opentype'),
             url('./Roboto/roboto-v27-latin-900italic.woff2') format('woff2')
             url('./Roboto/roboto-v27-latin-900italic.woff') format('woff')
             url('./Roboto/roboto-v27-latin-900italic.ttf') format('truetype')
             url('./Roboto/roboto-v27-latin-900italic.svg#OpenSans') format('svg');
    }
}