.classic {
    @apply mb-10;

    &.card {
        @apply bg-white relative flex flex-col min-w-0 break-words bg-clip-border border border-gray-300 rounded-sm shadow-sm;

        & .card-header {
            @apply border-b border-gray-300;
        }
    
        & .card-footer {
            @apply border-t border-gray-300;
        }
    }

    & .card-header {
        @apply mb-0 rounded-sm px-16 py-12;
    }

    & .card-title {
        @apply m-0 font-medium text-14 tracking-wide;
    }

    & .card-body {
        @apply flex-1 p-18;
    }
    
    & .card-footer {
        @apply rounded-sm px-16 py-12;
    }
}