.compact-header {
    @apply flex justify-between items-center h-70;

    & .brand {
        @apply block w-full bg-gray-700 text-center h-70;
    
        & .logo {
            @apply flex items-center justify-center h-70 w-120;
    
            & > img {
                @apply w-auto h-70;
            }
        }
    }
}

.compact-sidebar {
    @apply fixed block w-120 h-screen top-70 bottom-0 z-20;
    
    ~ .main-content {
        @apply ml-120 overflow-hidden;
        
        & > .footer {
            @apply left-120;
        }
    }

    & .sidebar-layout {
        @apply relative pt-20 w-full h-full z-20 bg-gray-700;

        &-dark {
            @apply bg-gray-700;
        }
    }

    & .items {
        @apply flex-1 w-full px-20 space-y-12;

        .link {
            @apply relative block w-full p-12 rounded-md text-center font-medium text-gray-400 cursor-pointer;

            .icon {
                @apply inline-block text-32 leading-none;
            }

            .title {
                @apply block mt-3 transition-all duration-200 ease-linear;
            }
            
            &:hover {
                @apply bg-gray-600 transition-colors duration-200 ease-linear;
            }
            
            &:focus {
                @apply bg-gray-600 transition-colors duration-200 ease-linear;
            }

            &.active {
                @apply bg-gray-600 text-gray-400;
            }
        }
    }

    & .detail {
        @apply absolute bg-white top-0 bottom-0 p-20 w-300 -left-220 overflow-y-hidden z-10 transition-all duration-200 ease-linear border-t border-light;

        &.open {
            @apply left-120 shadow;
        }

        hr {
            @apply border-gray-200 mb-5;
        }

        h6 {
            @apply mb-5;
        }

        a {
            @apply flex pl-10 text-lg items-center mb-12 text-gray-500;

            span {
                @apply mr-10 text-18 leading-none text-gray-500;
            }

            &:hover {
                @apply text-primary;

                span {
                    @apply text-primary;
                }
            }

            &.active {
                @apply text-primary;

                span {
                    @apply text-primary;
                }
            }
        }
    }
}