.badge {
    @apply inline-block px-8 text-10 text-white text-center whitespace-nowrap align-baseline rounded;

    &-primary {
        @apply bg-primary;
    }

    &-secondary {
        @apply bg-light text-gray-500;
    }

    &-sucess {
        @apply bg-green-500 ;
    }

    &-danger {
        @apply bg-red-500 ;
    }

    &-warning {
        @apply bg-yellow-500 ;
    }

    &-info {
        @apply bg-blue-500 ;
    }
    
    &-outline-primary {
        @apply text-primary border border-primary;
    }

    &-outline-secondary {
        @apply text-gray-500 border border-light-800;
    }

    &-outline-sucess {
        @apply text-green-500 border border-green-500;
    }

    &-outline-danger {
        @apply text-red-500 border border-red-500;
    }

    &-outline-warning {
        @apply text-yellow-500 border border-yellow-500;
    }

    &-outline-info {
        @apply text-blue-500 border border-blue-500;
    }
}

.rounded-pill {
    @apply rounded-full
}