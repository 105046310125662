.alert {
    @apply relative p-16 mb-16 rounded;

    &-rounded {
        @apply rounded-full;
    }

    &-primary {
        @apply bg-primary-100 text-primary;
    }

    &-secondary {
        @apply bg-gray-300 text-gray-500;
    }

    &-sucess {
        @apply bg-green-200 text-green-500;
    }

    &-danger {
        @apply bg-red-200 text-red-500;
    }

    &-warning {
        @apply bg-yellow-200 text-yellow-500;
    }
    
    &-info {
        @apply bg-blue-200 text-blue-500;
    }
    
    &-outline-primary {
        @apply bg-transparent border border-primary-100 text-primary;
    }

    &-outline-secondary {
        @apply bg-transparent border border-gray-300 text-gray-500;
    }
    
    &-outline-sucess {
        @apply bg-transparent border border-green-200 text-green-500;
    }

    &-outline-danger {
        @apply bg-transparent border border-red-200 text-red-500;
    }

    &-outline-warning {
        @apply bg-transparent border border-yellow-200 text-yellow-500;
    }
    
    &-outline-info {
        @apply bg-transparent border border-blue-200 text-blue-500;
    }
}