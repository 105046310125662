.widget {
    &.card {
        @apply bg-white relative flex flex-col min-w-0 break-words bg-clip-border rounded-md shadow-sm;

        & .card-header {
            @apply border-b border-light-600;
        }

        & .card-footer {
            @apply border-t border-light-600;
        }
    }
        
    & .card-header {
        @apply mb-0 rounded-t-md px-16 py-12;
    }

    & .card-title {
        @apply m-0 font-medium text-14 tracking-wide;
    }

    & .card-body {
        @apply flex-1 p-18;
    }

    & .card-footer {
        @apply rounded-b-md px-16 py-12;
    }
}