.form-check {
    @apply block mb-2;

    & .form-check-input {
        @apply float-left;
    }
}

.form-check-inline {
    @apply inline-block mr-16;
}

.form-check-input {
    @apply w-16 h-16 mt-4 bg-no-repeat bg-center bg-contain border border-gray-400 appearance-none cursor-pointer align-top;
    color-adjust: exact;

    &[type="checkbox"] {
        @apply rounded;
    }

    &[type="radio"] {
        @apply rounded-full;
    }

    &:checked {
        @apply bg-blue-500 border-blue-500;

        &[type="checkbox"] {
            background-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }

        &[type="radio"] {
            background-image:  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
        }
    }

    &:disabled {
        @apply pointer-events-none opacity-5;
    }

    &[disabled], &:disabled {
        ~ .form-check-label {
            @apply opacity-5;
        }
    }
}

.form-check-label {
    @apply mx-4 my-2 cursor-pointer;
}